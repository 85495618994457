export const TravelStatus = {
    InTransit: 'In Motion',
    Origin: 'Origin',
    Destination: 'Destination'
};

export const TravelStatuses = [
    TravelStatus.InTransit,
    TravelStatus.Origin,
    TravelStatus.Destination
];